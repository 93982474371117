import * as React from "react";
import { Layout } from "../components/common/Layout";
import { SectionContainer } from "../components/common/SectionContainer";
import { SectionTitle } from "../components/common/SectionTitle";
import { useProjectsData } from "../data/hooks/useProjectsData";
import { Grid, Stack, Button } from "@mui/material";
import { PortfolioItem } from "../components/portfolio/PortfolioItem";
import { Link } from "gatsby";

function GetProjects() {
  const projects = useProjectsData();
  const featuredProjects = projects.filter((project) => project.featured);
  const otherProjects = projects.filter((project) => !project.featured);

  return [...featuredProjects, ...otherProjects];
}

export default function ProjectsPage() {
  const projects = GetProjects();
  return (
    <Layout title="All Projects" includeFooter includeHeader includeSocials>
      <SectionContainer vPadding="200px">
        <SectionTitle title="All Projects" gutterBottom />
        <Grid container spacing={2}>
          {projects.map((x) => {
            return <PortfolioItem item={x} key={x.id + "_item"} />;
          })}
        </Grid>
        <Stack
          spacing={2}
          direction="row"
          alignSelf="center"
          sx={{ marginTop: "80px" }}
        >
          <Button
            variant="contained"
            sx={{ fontWeight: "bold" }}
            component={Link}
            to="/"
          >
            back home
          </Button>
        </Stack>
      </SectionContainer>
    </Layout>
  );
}
